import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';

const Image = ({
  image, imgStyle, style, className,
}) => {
  const data = useStaticQuery(graphql`
    fragment defaultImage on File {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          breakpoints: [2560]
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    fragment defaultImageMobile on File {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          breakpoints: [1000]
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    fragment smallImage on File {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          breakpoints: [400]
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    fragment headerImage on File {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          breakpoints: [400]
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    query {
      headerManagedHosting: file(relativePath: { eq: "headers/managed-hosting.jpg" }) {
        ...defaultImage
      }
      headerManagedHostingMobile: file(relativePath: { eq: "headers/managed-hosting-sm.jpg" }) {
        ...defaultImageMobile
      }
      headerSecurityCompliance: file(relativePath: { eq: "headers/security-compliance.jpg" }) {
        ...defaultImage
      }
      headerSecurityComplianceMobile: file(
        relativePath: { eq: "headers/security-compliance-sm.jpg" }
      ) {
        ...defaultImageMobile
      }
      headerOverigeDiensten: file(relativePath: { eq: "headers/overige-diensten.jpg" }) {
        ...defaultImage
      }
      headerOverigeDienstenMobile: file(relativePath: { eq: "headers/overige-diensten-sm.jpg" }) {
        ...defaultImageMobile
      }
      headerVacatures: file(relativePath: { eq: "headers/vacatures.jpg" }) {
        ...defaultImage
      }
      headerVacaturesMobile: file(relativePath: { eq: "headers/vacatures-sm.jpg" }) {
        ...defaultImageMobile
      }
      headerVacature: file(relativePath: { eq: "headers/vacature.jpg" }) {
        ...defaultImage
      }
      headerVacatureMobile: file(relativePath: { eq: "headers/vacature-sm.jpg" }) {
        ...defaultImageMobile
      }
      headerOverOns: file(relativePath: { eq: "headers/over-ons.jpg" }) {
        ...defaultImage
      }
      headerOverOnsMobile: file(relativePath: { eq: "headers/over-ons-sm.jpg" }) {
        ...defaultImageMobile
      }
      headerKantoor: file(relativePath: { eq: "headers/kantoor.jpg" }) {
        ...defaultImage
      }
      headerKantoorMobile: file(relativePath: { eq: "headers/kantoor-sm.jpg" }) {
        ...defaultImageMobile
      }
      headerTeam: file(relativePath: { eq: "headers/team.jpg" }) {
        ...defaultImage
      }
      headerTeamMobile: file(relativePath: { eq: "headers/team-sm.jpg" }) {
        ...defaultImageMobile
      }
      headerSiteReliability: file(relativePath: { eq: "headers/site-reliability-engineer.jpg" }) {
        ...defaultImage
      }

      pagesHomeGreenberry: file(relativePath: { eq: "pages/home-greenberry.jpg" }) {
        ...headerImage
      }
      pagesHomeManagedHosting: file(relativePath: { eq: "pages/home-managed-hosting.jpg" }) {
        ...headerImage
      }
      pagesHomeHq: file(relativePath: { eq: "pages/home-hq.jpg" }) {
        ...headerImage
      }
      pagesHomeJoinUs: file(relativePath: { eq: "pages/home-join-us.jpg" }) {
        ...headerImage
      }
      pagesCasesWebIQ: file(relativePath: { eq: "pages/cases-web-iq.jpg" }) {
        ...smallImage
      }
      pagesCasesRedJePakketje: file(relativePath: { eq: "pages/cases-red-je-pakketje.jpg" }) {
        ...smallImage
      }
      pagesOverOnsHackathon: file(relativePath: { eq: "pages/over-ons-hackathon.jpg" }) {
        ...smallImage
      }
      pagesOverOnsGameRoom: file(relativePath: { eq: "pages/over-ons-game-room.jpg" }) {
        ...smallImage
      }
      pagesOverOnsFriet: file(relativePath: { eq: "pages/over-ons-friet.jpg" }) {
        ...smallImage
      }
      pagesSecurityComplianceCertificates: file(
        relativePath: { eq: "pages/security-compliance-certificates.jpg" }
      ) {
        ...smallImage
      }

      pageKantoorPand1: file(relativePath: { eq: "pages/kantoor-pand1.jpg" }) {
        ...smallImage
      }
      pageKantoorPand2: file(relativePath: { eq: "pages/kantoor-pand2.jpg" }) {
        ...smallImage
      }
      pageKantoorPand3: file(relativePath: { eq: "pages/kantoor-pand3.jpg" }) {
        ...smallImage
      }
      pageKantoorPand4: file(relativePath: { eq: "pages/kantoor-pand4.jpg" }) {
        ...smallImage
      }
      pageKantoorPand5: file(relativePath: { eq: "pages/kantoor-pand5.jpg" }) {
        ...smallImage
      }
      pageKantoorPand6: file(relativePath: { eq: "pages/kantoor-pand6.jpg" }) {
        ...smallImage
      }

      footerSignUp: file(relativePath: { eq: "footers/sign-up.jpg" }) {
        ...defaultImage
      }
      footerGetReady: file(relativePath: { eq: "footers/get-ready.jpg" }) {
        ...defaultImage
      }
      footerOurApproach: file(relativePath: { eq: "footers/our-approach.jpg" }) {
        ...defaultImage
      }
      footerCases: file(relativePath: { eq: "footers/cases.jpg" }) {
        ...defaultImage
      }
      footerSecurity: file(relativePath: { eq: "footers/security.jpg" }) {
        ...defaultImage
      }
      footerJoinUs: file(relativePath: { eq: "footers/join-us.jpg" }) {
        ...defaultImage
      }
      footerAreYouNext: file(relativePath: { eq: "footers/are-you-next.jpg" }) {
        ...defaultImage
      }
      footerOffice: file(relativePath: { eq: "footers/office.jpg" }) {
        ...defaultImage
      }
      footerVacatures: file(relativePath: { eq: "footers/vacatures.jpg" }) {
        ...defaultImage
      }
      footerVoorwie: file(relativePath: { eq: "footers/voorwie.jpg" }) {
        ...defaultImage
      }
      footerWorkflow: file(relativePath: { eq: "footers/workflow.jpg" }) {
        ...defaultImage
      }
    }
  `);

  const fluid = typeof data[`${image}Mobile`] !== 'undefined'
    ? withArtDirection(getImage(data[image]), [
      {
        media: '(max-width: 768px)',
        image: getImage(data[`${image}Mobile`]),
      },
    ])
    : getImage(data[image]);

  return (
    <GatsbyImage
      image={fluid}
      className={classNames('default-image', className)}
      imgStyle={imgStyle}
      style={style}
      alt=""
    />
  );
};

Image.defaultProps = {
  imgStyle: { objectPosition: 'center center' },
  style: {},
  className: false,
};

Image.propTypes = {
  image: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  imgStyle: PropTypes.shape({
    objectPosition: PropTypes.string.isRequired,
  }),
  style: PropTypes.shape({
    position: PropTypes.string,
  }),
};

export default Image;
